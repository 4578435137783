<app-outlet>
    <p-card>
        <ng-template pTemplate="header">
            <div class="flex justify-between items-center p-4">
                <span class="text-xl font-bold">Settings</span>
                <div class="space-x-4">
                    <button class="px-6 py-2 bg-white rounded-sm border text-vendblocpurple border-vendblocpurple"
                    (click)="toggleDeliveryLocationDialog()" type="button">Manage Delivery Location</button>
                    <button class="px-6 py-2 bg-white rounded-sm border text-vendblocpurple border-vendblocpurple"
                    (click)="toggleOpeningTimeDialog()" type="button">Set Opening/Closing Time</button>
                </div>

            </div>
        </ng-template>
        <form [formGroup]="updateOutletForm" (submit)="updateOutlet()">
            <div>
                <div class="flex mb-2 space-x-2">
                    <div class="flex flex-col w-[50%]">
                        <app-text-input-field ngDefaultControl label="Name" inputId="name" placeHolder="Enter Name" [formControl]="updateOutletForm.controls.name"></app-text-input-field>
                    </div>
                    <div class="flex flex-col w-[50%]">
                        <app-text-input-field ngDefaultControl label="Phone" inputId="phone" placeHolder="Enter Phone" [formControl]="updateOutletForm.controls.phone"></app-text-input-field>
                    </div>
                </div>
                <div class="flex mb-2 space-x-2">
                    <div class="flex flex-col w-[50%]">
                        <app-text-input-field ngDefaultControl label="Address" inputId="address" placeHolder="Enter Address" [formControl]="updateOutletForm.controls.address"></app-text-input-field>
                    </div>
                    <div class="flex flex-col w-[50%]">
                        <app-text-input-field ngDefaultControl label="Email" inputId="email" placeHolder="Enter Email" [formControl]="updateOutletForm.controls.email"></app-text-input-field>
                    </div>
                </div>
                <div class="flex mb-2 space-x-2">
                    <div class="flex flex-col w-[50%]">
                        <div class="flex flex-row justify-between">
                            <div class="w-[45%]">
                                <app-file-upload [formControl]="outletImageForm" label="Outlet Cover" ngDefaultControl [required]="false" [maxFileSize]="250000"
                                (fileUploaded)="onUpload($event)"></app-file-upload>
                            </div>
                            <div class="w-[45%]">
                                <app-file-upload [formControl]="outletBannerForm" label="Outlet Banner" ngDefaultControl [required]="false" [maxFileSize]="500000"
                                (fileUploaded)="onUpload($event)" ></app-file-upload>
                            </div>
                        </div>
                        
                    </div>
                    <div class="flex flex-col w-[50%]">
                        <div class="flex flex-row justify-between w-[100%]">
                            <p-checkbox class="p-2 custom-checkbox" label="Online Order" [binary]="true" formControlName="onlineOrder" />
                            <p-checkbox class="p-2 custom-checkbox" [binary]="true" formControlName="onlineDelivery" label="Delivery" />
                        </div>
                        <div class="flex flex-row justify-between w-[100%]">
                            <p-checkbox class="p-2 custom-checkbox" label="Less than zero inventory" [binary]="true" formControlName="lessThanZeroInvetory" />
                            <p-checkbox class="p-2 custom-checkbox" label="Private Delivery Riders" [binary]="true" formControlName="inHouseDispatchersOnly" />
                        </div>
                    </div>
                </div>
                <div class="flex justify-end items-end">
                    <button class="px-6 py-2 text-white rounded-sm bg-vendblocpurple" type="submit">Update</button>
                </div>
            </div>
        </form>   
    </p-card>
    <app-dialog [(visible)]="manageDeliveryLocationDialog" (cancel)="onDeliveryLocationDialogCancel()" (hide)="onDeliveryLocationDialogCancel()" [draggable]="false" [style]="{ width: '50vw' }" header="Delivery Locations"
       styleClass="p-fluid">
            <div class="m-0 space-y-2 w-full md:flex-col md:p-1 lg:p-3 xl:p-4">
                <div class="flex flex-col space-y-3">
                    @if(this.outlet){
                    <form [formGroup]="createDeliveryLocationForm" (submit)="createOutletDeliveryLocation()">
                        <div class="flex flex-col">
                            <app-text-input-field ngDefaultControl label="" inputId="location"
                                placeHolder="Enter Location"
                                [formControl]="createDeliveryLocationForm.controls.location"></app-text-input-field>
                            <div class="flex mb-2 space-x-3">
                                <div class="w-1/2">
                                    <app-text-input-field ngDefaultControl label="" inputId="dayAmount"
                                        placeHolder="Enter Day Amount"
                                        [formControl]="createDeliveryLocationForm.controls.dayAmount"></app-text-input-field>
                                </div>
                                <div class="w-1/2">
                                    <app-text-input-field ngDefaultControl label="" inputId="nightAmount"
                                        placeHolder="Enter Night Amount"
                                        [formControl]="createDeliveryLocationForm.controls.nightAmount"></app-text-input-field>
                                </div>
                            </div>
                            <button class="px-6 py-2 text-white rounded-sm bg-vendblocpurple"
                                type="submit">Add</button>
                        </div>
                    </form>
                    <p class="text-lg font-bold text-center">Delivery Locations</p>
                    <table class="w-full">
                        <thead>
                            <tr>
                                <th class="font-bold text-left">Location</th>
                                <th class="font-bold text-left">Day</th>
                                <th class="font-bold text-left">Night</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (location of this.outlet.deliveryLocations; track $index) {
                            <tr class="border-b">
                                <td class="py-2">{{$index + 1}}. {{location.location}}</td>
                                <td class="py-2">{{location.nightAmount}}</td>
                                <td class="py-2">{{location.nightAmount}}</td>
                                <td class="py-2">
                                    <p-button (click)="deleteLocation(location.id)" 
                                             severity="danger"
                                             icon="pi pi-trash text-vendblocred">
                                    </p-button>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                    }
                </div>
            </div>
    </app-dialog>

    <app-dialog [(visible)]="openingTimeDialog" 
                (cancel)="onTimeSlotDialogCancel()" 
                (hide)="onTimeSlotDialogCancel()"
                (confirm)="updateTimeSlot()"
                [draggable]="false" 
                [style]="{ width: '40vw' }" 
                header="Configure Opening Hours"
                styleClass="p-fluid">
        <div class="m-0 space-y-4 w-full md:flex-col md:p-1 lg:p-3 xl:p-4">
            <div>
                Current Opening/Closing Time : {{this.outlet.opening}}/{{this.outlet.closing}}
            </div>
            <form>
                <div class="flex flex-col space-y-4">
                    <div class="flex space-x-4">
                        <div class="w-1/2">
                            <app-text-input-field 
                                ngDefaultControl 
                                label="Opening Time" 
                                inputId="openingTime"
                                inputType="time"
                                [formControl]="timeSlotForm.controls.openingTime">
                            </app-text-input-field>
                        </div>
                        <div class="w-1/2">
                            <app-text-input-field 
                                ngDefaultControl 
                                label="Closing Time" 
                                inputId="closingTime"
                                inputType="time"
                                [formControl]="timeSlotForm.controls.closingTime">
                            </app-text-input-field>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </app-dialog>
</app-outlet>
